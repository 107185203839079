<template>
    <html>
      <body class="" style="background: #FFF">
        <Header />
        <MenuLateralMobile />
        <div class="h-screen grid grid-cols-6 grid-rows-12 grid-flow-col">
           <MenuLateral/>
            <div class="col-span-6" style="background:#FFF;padding:20px;">
                <CustomerSearchComponent />
            </div>
        </div>
      </body>
    </html>
</template>


<script>
import Header from "@/components/Administrative/Header/Header.vue";
import MenuLateralMobile from '@/components/MenuLateralMobile.vue';
import MenuLateral from '@/components/LateralMenu.vue';
import CustomerSearchComponent from '@/components/Administrative/Products/CustomerSearch.vue';

export default {
  name: 'CustomerSearch',
  components: {
    Header,
    MenuLateralMobile,
    MenuLateral,
    CustomerSearchComponent
  }
}
</script>
