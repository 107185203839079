<template>
    <div>
        <Loading :isLoading="isLoading"/>
        <div class="flex ml-5 mt-5 cursor-pointer" @click="redirect_history()" >
            <img src="@/assets/back-arrow.svg" alt="image_arrow">
            Atrás
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 px-8 py-4">
            
            <div class="grid">
                <div>
                    <div class="mb-10">
                        <label for="">R.F.C.:</label>
                        <input type="text" placeholder="R.F.C.:" v-maska="'AAAA######XXX'" v-model="customerModel.rfc" @keyup.enter="findClientByRfc()" class="uppercase w-full py-2 px-2 rounded-md border-2 border-sm focus:border-blue-600 mb-2 mt-2 shadow-md focus:outline-none">
                        <div v-for="(error,index) of customerModelValidations.rfc.$errors" :key="index">
                            <div class="text-red-600" id="error_customer_rfc">{{ error.$message }}</div>
                        </div>
                    </div>
                    
                    <div class="float-right">
                        <input id="input_products_search_client" type="button" value="Buscar" class="bg-foncabsa_yellow text-white px-14 py-3 rounded-md cursor-pointer" @click="findClientByRfc()">
                    </div>
                </div>

                <div v-show="rfcRegistered" class="grid">
                    <div class="flex my-8">
                        <div style="display:table;">
                        <div style="display:table-cell;vertical-align:middle;">
                            <img src="@/assets/CapturaAsistida/triangle.svg" style="width:30px;" alt="image_triangle">
                        </div>
                        </div>
                
                        <div class="grid" style="display:table;margin-left:10px;">
                            <div style="display:table-cell;vertical-align:middle;">
                                <label class="text-2xl font-normal"> Información del Cliente </label>
                            </div>
                        </div>
                    </div>

                    <div class="grid pl-10">

                        <p class="flex text-lg font-normal mb-2">
                            <span class="bg-mustardDark w-3 h-3 my-auto mr-3"></span> {{ `${customerModel.name} ${customerModel.middle_name} ${customerModel.paternal_name} ${customerModel.maternal_name}` }}
                        </p>

                        <p class="flex text-lg font-normal mb-2">
                            <span class="bg-mustardDark w-3 h-3 my-auto mr-3"></span> {{ customerModel.age }} Años
                        </p>
                    
                    </div>
                </div>
            </div>

            <div v-show="rfcRegistered" class="px-5 md:px-24 py-2 md:py-4 mt-14 md:mt-0">
                <p class="text-3xl font-medium mb-5">Bienvenida</p>
                <p class="text-lg">
                    ¡Bienvenido a FONCABSA SFC!<br> 
                    Realizaremos una serie de preguntas para hacer una cotización de seguros
                </p>
                <div class="mt-10">
                    <button id="input_products_quotation" @click="next(`/${accountType}/productos/${product}/cotizacion/cliente/`)" class="w-full bg-foncabsa_yellow text-medium text-white px-14 py-3 rounded-md cursor-pointer">Cotizar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import { onMounted, ref, onErrorCaptured, watch, computed } from 'vue';
import { helpers, maxLength, minLength, requiredIf } from "@vuelidate/validators";
import Client from '@/classes/Client';
import Quote from '@/classes/Quotation.js';

import Swal from 'sweetalert2';
import useVuelidate from '@vuelidate/core';
import Loading from '@/components/Loading/VueLoading.vue';
import WsQuotation from "@/api/WsQuotation.js";
import { getTokenDecoden } from '@/helpers/tokenauth.js';

export default {
    name: 'VidaSimple',
    setup(){
        const router = useRouter();
        const route = useRoute();
        const quoteClass = ref(null);
        const isLoading = ref(true);
        const hasError = ref(false);
        const clientObjectClass = ref(null);
        const wsQuotationObjectClass = new WsQuotation();
        const rfcRegistered = ref(false);
        const accountType = ref("");
        const product = ref("");
        
        const rulesValidations = {
            rfc: {
                required: helpers.withMessage('El campo es requerido', requiredIf(true)),
                minLength: helpers.withMessage("El campo debe contener mínimo 13 caracteres",  minLength(13)),
                maxLength: helpers.withMessage("El campo debe contener máximo 13 caracteres",  maxLength(13)),
                structureIsValid: helpers.withMessage("El formato del RFC no es correcto. Ejemplo de formato correcto: LOMP8206281H0" , function (value, e) {
                    let isValid = true;
                    if(value != ""){
                        const validate = validateRfcStructure(value.toString().toUpperCase());
                        isValid = validate.status;
                    }else{
                        isValid = true;
                    }
                    if(isValid && e.curp == ""){
                        e.curp = value.toString().substr(0, 10);
                    }

                    return isValid;
                }),
                lazy: true,
            }
        }

        const customerModel = ref({
            account_user_id: 0,
            client_id: 0,
            rfc: "",
            birthday: "",
            name: "",
            middle_name: "",
            paternal_name: "",
            maternal_name: "",
            age: 0,
            marital_status:""
        });

        const sessionStoraceList = [
            "user-information",
            "user-detail"
        ]

        const localStoraceList = [
            "quote-description",
            "quote-vida-total",
            "quote-vida-simple",
            "quote-contact",
            "quote-medical-operations",
            "quote-smokes",
            "quote-coverage",
            "quote-sports",
            "quote-alcohol",
            "quote-personal-data",
            "quote-diseases",
            "quote-hobbies",
            "quote-recruitment-data",
            "quote-occupations",
            "quote-detail",
            "issuance-vida-total",
            "issuance-vida-simple",
            "issuance-address",
            "issuance-labor-information",
            "issuance-collection",
            "issuance-beneficiaries",
            "document_ine",
            "document_proof_of_address",
            "issuance-detail"
        ];

        let customerModelValidations = useVuelidate(rulesValidations, customerModel);
        
        const updateProduct = computed(()=>{
            return route.params.product;
        });
        
        onErrorCaptured((error) => {
            console.error(error);
            hasError.value = true;
            return false;
        });

        onMounted(()=>{
            getParamsUrl();
            clearStorage();
            quoteClass.value = new Quote();
            quoteClass.value.save(product.value, quoteClass.value);
            
            clientObjectClass.value = new Client({});
            
            isLoading.value = false;
        });

        watch(updateProduct, ()=> {
            clear();
        });

        const clear = () => {
            rfcRegistered.value = false;
            customerModel.value.account_user_id = 0;
            customerModel.value.client_id = 0;
            customerModel.value.rfc = "";
            customerModel.value.birthday = "";
            customerModel.value.name = "";
            customerModel.value.middle_name = "";
            customerModel.value.paternal_name = "";
            customerModel.value.maternal_name = "";
            customerModel.value.age = 0;
            customerModel.value.marital_status = "";
            customerModelValidations.value.$reset();
        }
        

        const getParamsUrl = () => {
            accountType.value = route.params.role;
            product.value = route.params.product;
        }

        const findClientByRfc = async () => {
            rfcRegistered.value = false;
            customerModelValidations.value.$validate();
            if(customerModelValidations.value.$error) return;
            
            isLoading.value = true;
            const token = getToken();
            const response = await clientObjectClass.value.getApplicantInfoToQuoteGroupLifeByRfc(token, customerModel.value.rfc).then(response => {
                return {status: true, data: response.data.response.result, message: response.data.response.message}
            }).catch(error => {
                return {status: false, data: null, message: error}
            }).finally(()=> { isLoading.value = false; });

            if(!response.status || response.data == ""){
                showMessage((response.status)? 'info': 'error', (response.status)? 'Aviso': 'Error', response.message);
                return;
            }

            customerModel.value.account_user_id = (response.data.user_data.account_user_id != null) ? response.data.user_data.account_user_id: "";
            customerModel.value.client_id = (response.data.user_data.client_id != null) ? response.data.user_data.client_id: "";
            customerModel.value.rfc = (response.data.user_data.rfc != null) ? response.data.user_data.rfc: "";
            customerModel.value.name = (response.data.user_data.name != null) ? response.data.user_data.name: "";
            customerModel.value.middle_name = (response.data.user_data.middle_name != null) ? response.data.user_data.middle_name: "";
            customerModel.value.paternal_name = (response.data.user_data.paternal_name != null) ? response.data.user_data.paternal_name: "";
            customerModel.value.maternal_name = (response.data.user_data.maternal_name != null) ? response.data.user_data.maternal_name: "";
            customerModel.value.birthday = response.data.user_data.birthday;
            customerModel.value.age = (response.data.user_data.age != null) ? response.data.user_data.age : 0;
            rfcRegistered.value = true;

            setSessionStorage('user-information', response.data)
            
            const tokenDecoden = getTokenDecoden();
            quoteClass.value.client_id = customerModel.value.client_id;
            quoteClass.value.user_id = tokenDecoden.obj.id;
            quoteClass.value.save(product.value, quoteClass.value);
        }

        const getToken = () => {
            return sessionStorage.getItem('login');
        }

        const setSessionStorage = (name, value) => {
            return sessionStorage.setItem(name, JSON.stringify(value));
        }
        
        const validateRfcStructure = (rfc) => {
            let rfc_pattern =
                "^(([A-ZÑ&]{4})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
                "(([A-ZÑ&]{4})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
                "(([A-ZÑ&]{4})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
                "(([A-ZÑ&]{4})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";
            const coincidences = rfc.match(rfc_pattern);
            
            const data = [];
            if(coincidences!=null){
                coincidences.forEach(element => {
                if(typeof element != 'undefined'){
                    data.push(element);
                }
                });
            }
            return coincidences == null ? {'status': false, 'coincidences': data} : {'status': true, 'coincidences': data};
        }

        const next = async (route) => {
            if(parseInt(customerModel.value.age) > 69 && product.value == "vida-total"){
                showMessage('info', 'Aviso', 'La edad máxima para cotizar este producto es de 69 años.');
                return;
            }

            isLoading.value = true;

            const token = getToken();
            const userInfoQuote = await getUserInformation(token, customerModel.value.account_user_id);
            if(!userInfoQuote.status){
                return;
            }

            setSessionStorage('user-detail', userInfoQuote.data)

            let response = await generateAuthorizationToken().finally(()=>{
                isLoading.value = false;
            });

            if(response.status){
                createStorage('tokenAccess', response.data)
                router.push(route);
                return;
            }

            showMessage((response.status)? 'success': 'error', (response.status)? 'Aviso': 'Error', response.message);
        }

        const getUserInformation = async (token, userId) => {
            let response = await wsQuotationObjectClass.getUserInformation(token, userId).then(response => { return {status: true, data: response.data.response.simple_life_info, message: response.data.response.message} }).catch(error => {return {status: false, data: null, message: error}});

            if(!response.status){
                showMessage((response.status)? 'info': 'error', (response.status)? 'Aviso': 'Error', response.message);
                return;
            }

            return response;
        }

        const generateAuthorizationToken = async () => {
            let response =  await wsQuotationObjectClass.getToken().then(resp => { 
                return { status: true, data: resp.data.response.token_auth, message: resp.data.response.message }
            }).catch((err) => { 
                return { status: false, data: null, message: err.response.data.response.message }
            });

            return response;
        }

        const createStorage = (name, value) => {
            sessionStorage.setItem(name, JSON.stringify(value));
        }

        const clearStorage = () => {
            localStoraceList.forEach(function(key) {
                localStorage.removeItem(key); // Cambiar a sessionStorage si corresponde
            });
            sessionStoraceList.forEach(function(key) {
                sessionStorage.removeItem(key);
            })
        }

        const showMessage = (type, title, message, confirmButtonText = "OK", confirmButtonColor= "#FEB72B") => {
            Swal.fire({
                title: title,
                html: message,
                icon: type,
                confirmButtonText: confirmButtonText,
                confirmButtonColor: confirmButtonColor
            });
        }

        const redirect_history = () => router.go(-1);
        
        return {
            isLoading,
            customerModel,
            customerModelValidations,
            rfcRegistered,
            accountType,
            product,
            findClientByRfc,
            next,
            redirect_history
        };
    },
    components:{
        Loading
    }
}

</script>